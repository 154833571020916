import React, { useState } from 'react';
import './PublicRightSide.css';
import  PublicFiles from './PublicFiles';
import Details from '../DetailsBarComponent/Details';
import imageGallery from '../../Assets/Icons/newdoc.svg';
import documents from '../../Assets/Icons/documents.svg';
import media from '../../Assets/Icons/media.svg';
import more from '../../Assets/Icons/more.svg';
import  PublicRightSideTopBar from '../RightSideTopComponent/RightSideTopBar';
import folderIcon from '../../Assets/Icons/folder.png';
import AppContext from '../../context/AppContext';
import { Button } from '@mui/material';
import DragDropPopUp from '../DragDrop/DragDrop';
import  PublicTopBar from './PublictopBar';

const  PublicRightSide = props => {
  return (
    <AppContext.Consumer>
      {context => (
        <div className='right-side'>
          {props.showRightSide ? (
            <div className='right-middle'>
              <div
                className='images'
                onClick={() => {
                  // props.setShowRightSide(false);
                  // props.setShowImagesFolder(true);
                  // props.setRoot('Images');
                  // context?.getData();
                  // context?.getSubFolderData();
                }}>
                <img src={more} className='file-more' />
                <img alt='' src={imageGallery} className='main-folders-icon' />
                <p className='main-folder-name'>UI-File 2021/22</p>
                {/* <p className='main-folder-size'>14 GB</p> */}
                <p className='main-folder-files'>2 hours ago</p>
              </div>
            </div>
          ) : (
            ''
          )}
          

          <div style={{ display: props.showDetails ? 'flex' : '' }}>
            {props.showTopBar && (
              < PublicTopBar
              onDragEnter={() => context?.setDragPopup(true)}
                showRightSide={props.showRightSide}
                setShowRightSide={props.setShowRightSide}
                showImagesFolder={props.FoldershowImagesFolder}
                setShowImagesFolder={props.setShowImagesFolder}
                root={props.root}
                setRoot={props.setRoot}
                mainRoot={props.mainRoot}
                showDetails={props.showDetails}
                setShowDetails={props.setShowDetails}
                middleMaxWidth={props.middleMaxWidth}
                setMiddleMaxWidth={props.setMiddleMaxWidth}
                createSubFolder={props.createSubFolder}
                setCreateSubFolder={props.setCreateSubFolder}
                showSubFolder={props.showSubFolder}
                setShowSubFolder={props.setShowSubFolder}
                isUploaded={context.isUploaded}
                anchorEl={props.anchorEl}
                setAnchorEl={props.setAnchorEl}
                renameSelectedFile={props.renameSelectedFile}
                setRenameSelectedFile={props.setRenameSelectedFile}
                shareSelectedFile={props.shareSelectedFile}
                setShareSelectedFile={props.setShareSelectedFile}
                detailsBarType={props.detailsBarType}
                setDetailsBarType={props.setDetailsBarType}
                detailsBarFileName={props.detailsBarFileName}
                setDetailsBarFileName={props.setDetailsBarFileName}
                detailsBarModified={props.detailsBarModified}
                SetdetailsBarModified={props.SetdetailsBarModified}
                detailsBarCreatedDate={props.detailsBarCreatedDate}
                setDetailsBarCreatedDate={props.setDetailsBarCreatedDate}
                detailsBarLocation={props.detailsBarLocation}
                setDetailsBarLocation={props.setDetailsBarLocation}
                uplodingStatus={props.uplodingStatus}
                setUplodingStatus={props.setUplodingStatus}
                files={props.files}
                setFiles={props.setFiles}
                folderSelected={props.folderSelected}
                setFolderSelected={props.setFolderSelected}
                filePreview={props.filePreview}
                setFilePreview={props.setFilePreview}
              
              />

                
              
            )}
            {props.showImagesFolder && (
              
              < PublicFiles
                onDragEnter={() => context?.setDragPopup(true)}
                showRightSide={props.showRightSide}
                setShowRightSide={props.setShowRightSide}
                showImagesFolder={props.FoldershowImagesFolder}
                setShowImagesFolder={props.setShowImagesFolder}
                root={props.root}
                setRoot={props.setRoot}
                mainRoot={props.mainRoot}
                showDetails={props.showDetails}
                setShowDetails={props.setShowDetails}
                middleMaxWidth={props.middleMaxWidth}
                setMiddleMaxWidth={props.setMiddleMaxWidth}
                createSubFolder={props.createSubFolder}
                setCreateSubFolder={props.setCreateSubFolder}
                showSubFolder={props.showSubFolder}
                setShowSubFolder={props.setShowSubFolder}
                isUploaded={context.isUploaded}
                anchorEl={props.anchorEl}
                setAnchorEl={props.setAnchorEl}
                renameSelectedFile={props.renameSelectedFile}
                setRenameSelectedFile={props.setRenameSelectedFile}
                shareSelectedFile={props.shareSelectedFile}
                setShareSelectedFile={props.setShareSelectedFile}
                detailsBarType={props.detailsBarType}
                setDetailsBarType={props.setDetailsBarType}
                detailsBarFileName={props.detailsBarFileName}
                setDetailsBarFileName={props.setDetailsBarFileName}
                detailsBarModified={props.detailsBarModified}
                SetdetailsBarModified={props.SetdetailsBarModified}
                detailsBarCreatedDate={props.detailsBarCreatedDate}
                setDetailsBarCreatedDate={props.setDetailsBarCreatedDate}
                detailsBarLocation={props.detailsBarLocation}
                setDetailsBarLocation={props.setDetailsBarLocation}
                uplodingStatus={props.uplodingStatus}
                setUplodingStatus={props.setUplodingStatus}
                files={props.files}
                setFiles={props.setFiles}
                folderSelected={props.folderSelected}
                setFolderSelected={props.setFolderSelected}
                filePreview={props.filePreview}
                setFilePreview={props.setFilePreview}
                
                
              />
            )}

            {props.showDetails && (
              <Details
                showDetails={props.showDetails}
                setShowDetails={props.setShowDetails}
                setMiddleMaxWidth={props.setMiddleMaxWidth}
                detailsBarType={props.detailsBarType}
                detailsBarFileName={props.detailsBarFileName}
                detailsBarModified={props.detailsBarModified}
                detailsBarCreatedDate={props.detailsBarCreatedDate}
                detailsBarLocation={props.detailsBarLocation}
              />
            )}

            {/* {props.showDetails ? (

          ): (
              ''
            )} */}
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default  PublicRightSide;
