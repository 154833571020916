import React, { useContext, useState, useRef } from 'react';
import './LeftSideBar.css';
import Newfolder from '../ModalComponents/NewFolder';
import searchIcon from '../../Assets/Icons/search-normal.svg';
import search from '../../Assets/Icons/search.svg';
import more from '../../Assets/Icons/more.svg';
import AddButton from '../../Assets/Icons/addcircle.svg';
import leftFolder from '../../Assets/Icons/foldermiddle.svg';
import RightArrow from '../../Assets/Icons/arrow-right1.svg';
import AppContext from '../../context/AppContext';
import Button from '../../Components/Commen/Button/Button';
import ImageButton from '../../Components/Commen/Image Button/ImageButton';
import Input from '../../Components/Commen/Input/Input';
import Folder from './Folder';
import cloud from '../../Assets/Icons/cloud.svg';
import trash from '../../Assets/Icons/trash.svg';
import starred from '../../Assets/Icons/starred.svg';
import shared from '../../Assets/Icons/shared.svg';

// import Test from './Test';

const LeftSideBar = props => {
  const value = useContext(AppContext);
  const [imagePreview, setImagePreview] = useState(null);
  const [selected, setSelected] = useState('My Files');

  const [createNewFolder, setCreateNewFolder] = useState(false);
  const [selectedUpFold, setSelectedUpFold] = useState('');
  const [subFolderLoaded, setSubFolderLoaded] = useState(false)
  const [lables, setLables] = useState(["My Files", "Shared", "Favorites", "Starred"] );

  

  const select =() =>{

    if(value.selected === "Shared"){
    value.getShared();
  } else if (value.selected === "My Files"){
    value.getData();
  } else if (selected === "My Files"){
    value.getData(); 
}}
  
  const renderSubfolder = (subfolder, i) => {
    // console.log('working fine');
    // console.log(subfolder?.upFolder, 'jjjjjjjjjjjjjj');
    // console.log(selectedUpFold, 'kkkkkkkkkk');

    if (subfolder?.upFolder === selectedUpFold) {
      // console.log("helowwwwww")
      return (
        <div key={i} onClick={() => { }}>
          <h1 className='h1-left-folder'>{subfolder.file_name} </h1>
        </div>
      );
    }
    return null;
  };

  return (
    <AppContext.Consumer>
      {context => (
        <div className='left-side'>
          <div className='left-div'>
           

            <div className='search-div'>
              <img className='icons-search' src={search} alt='' />
              <Input customStyles={{ paddingLeft: '40px' }}  styles='search-bar-left1'  type='text'  placeholder='Search' name='search' />
            </div>

            
          </div>

          <div className='leftt-create-new' style={{ color: 'black' }}>
          
            <h5 className='h1-left-first' onClick={() => {
              localStorage.setItem("bixCloud-selectedupFolder", "null")
              context.getData()
              {context.setUpdate(context.update+1)}
            }}>
              Folders  <ImageButton styles="add-button"  ImgSource={AddButton}  action={setCreateNewFolder}  actionParam={!createNewFolder}  /> 
            </h5>
          </div>
      
          <div className='left-nav'>     
                <div className="box">
                  <img src={cloud} width="20px" height="20px"  alt="" />
                <button className='lable' onClick={ () => context.getData() }>My Cloud</button>
                </div>

                <div className="box">
                  <img src={shared} width="20px" height="20px"  alt="" />
                <button className='lable' onClick={ () => context.getShared() }>Shared with me</button>
                </div>

                <div className="box">
                  <img src={starred} width="20px" height="20px" alt="" />
                <button className='lable'>Trash</button>
                </div>

                <div className="box">
                  <img src={trash} width="20px" height="20px"  alt="" />
                <button className='lable'>Starred</button>
                </div>
                
                
                
                
                
                 
            </div>
          {createNewFolder ? (
            <Newfolder
              setShowFolders={props.setShowFolders}
              createNewFolder={createNewFolder}
              setCreateNewFolder={setCreateNewFolder}
            />
          ) : (
            ''
          )}
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default LeftSideBar;
