import React, { useState } from 'react';
import './PublicRightSideTopBar.css';
import dashboardIcon from '../../Assets/Icons/dashboard.svg';
import notificationIcon from '../../Assets/Icons/notification.svg';
import settingsIcon from '../../Assets/Icons/settings.svg';
import Propic from '../../Assets/Icons/pro-pic.PNG';
import AppContext from '../../context/AppContext';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

const  PublicRightSideTopBar = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  return (
    <AppContext.Consumer>
      {context => (
        <div className='body-top-bar'>
          {/* <div className='app-logo'>
             bix<span className='c-gradient'>C</span>
             <span style={{ color: '#dea60d', fontWeight: '600' }}>l</span>
             <span className='a-gradient'>o</span>
             <span className='a-gradient'>u</span>
             <span style={{ fontWeight: '600', color: '#3799f4' }}>d</span>
           </div> */}

          <h1 className='app-logo-simple'> BixCloud </h1>

          <div className='main-apps-div'>
            <h1 className='main-apps'> Home</h1>
            <h1 className='main-apps'> Chat</h1>
            <h1 className='main-apps'> Cloud</h1>
          </div>

          <div className='pro-pic-div-top1'>
            <div className="login">

            <div className="signUP">
            <button 
            className="signup"
            onClick={context?.loginPush()}
            >Sign Up</button>
            </div>
              
              <div className="signIN">
              <button 
              className="signin"
              onClick={context?.loginPush()}
              >Sign In</button>
              </div>
            </div>
          </div>

          <Popover
            id={id}
            anchorEl={anchorEl}
            d
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <Typography
              sx={{ width: 80, marginLeft: 2, marginTop: 1 }}
              onClick={() => {
                context?.logOut();
              }}>
              Log Out
            </Typography>
            <Typography
              sx={{ width: 80, marginLeft: 2, marginTop: 1, height: 30 }}>
              Settings
            </Typography>
          </Popover>

          {/* <div className='line'></div> */}
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default  PublicRightSideTopBar;
