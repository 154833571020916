import React, { useContext, useState, useRef } from 'react';
import { ReactDOM } from 'react';
import Modal from 'react-modal';
import Folder from '../Folder';
import './SharePopUp.css';
import Store from '../context';
import Share from '../../Assets/Icons/Share.svg';
import Options from '../../Assets/Icons/Options.svg';
import AppContext from '../../context/AppContext';
import RenameIcon from '../../Assets/Icons/renameIcon.svg';
import Propic from '../../Assets/Icons/pro-pic.PNG';
import close from '../../Assets/Icons/close.svg';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import User from '../Sharing_user/User';




// import {v4 as uuidv4} from "uuid";
const customStyles = {
  content: {
    top: '52%',
    left: '52%',
    right: 'auto',
    height: '55vh',
    width: '50%',
    bottom: 'auto',
    borderRadius: '10px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderTop: '15px solid #3799F4',
    overflowY: 'hidden',
    
  },
};




const SharePopUp = props => {
  let subtitle;
  let value = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const { dispatch } = useContext(Store);
  const [email,setEmail] = useState('')
  const [priority,setPriority] = useState('Admin');
  



  function openModal() {
    setLoader(true);
  }

  let url =  'https://api.bixchat.xyz/cloud/api/file/public/';
  
  const handleEmail = (e) =>{

      let emailValue = e.target.value;

      if (emailValue === '') {
          setEmail(" ");
      } else {
          setEmail(emailValue)
      }
  }
  let context = useContext(AppContext);
  const publicHandler = () =>{
    
    context?.publicShare(props.selectedFile);
  }

  const handlePrivilage = (e) =>{

    let priorityLevel = e.target.value

    setPriority(priorityLevel);
    console.log(priorityLevel);

    }
   
    

    const handleCopy  = () =>{
      navigator.clipboard.writeText(context?.publicUrl);
    }
  
  
 
  function closeModal() {
    setLoader(false);
  }
  function afterOpenModal() {
    // subtitle.style.color = 'rgb(15, 15, 15)';
  };


  
  const handleFocus = event => event.target.select();

  return (
    <AppContext.Consumer>
      {context => (
        <div className='new-folder-mod'>
          <Modal
            isOpen={loader}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel='Example Modal'
            ariaHideApp={false}>
            <div className='row'>
              <div className='col-md-12'>
                <br />
                <div className='input-group'>
                  <div className='popup-up'>
                    <img className='icon-rename' src={Share} />
                    <h2
                      className='modal-name-rename'
                      ref={_subtitle => (subtitle = _subtitle)}>
                      {' '}
                      Share
                    </h2>
                    <img
                      className='close-button-rename'
                      src={close}
                      onClick={() => {
                        props.openShareModal();
                      }}
                    />
                  </div>
                  
                        <div className='select_container'>
                  <input
                    onFocus={handleFocus}
                    className='box-rename'
                    autoFocus={true}
                    placeholder={"User name / Group name"}
                    // onKeyUp={handleSubmitForm}
                    onChange={e => {
                      context.setShareableUser(e.target.value);
                    }}
                  />

                  <div className='selector'
                  onChange={(e) => {handlePrivilage(e)}}
                  >


                  <select className='select'>
                    <option defaultValue={"Admin"}>Admin</option>
                    <option value="View">View</option>

                    

                  </select>
                  
                  <button className='send'
                    onClick={() =>{
                    console.log("idk");
                      context?.shareFile(props.selectedFile);

                      

                    }}>

                    Send
                  </button>

                  
                  </div>

                  </div>
                    <div className="public">
                    <button className='public-trigger'
                    onClick={publicHandler}
                    >Share Public</button>
                    </div>
                  <User/>
                  
                </div>

                
                <div className='Option'>
                <div className='popup-up'>
                
                    <img className='icon-rename' src={Options} />
                    <h2
                      className='modal-name-rename'
                      ref={_subtitle => (subtitle = _subtitle)}>
                      {' '}
                      Options
                    </h2>
                    
                  </div>
                    <div className='select_container'>
                    <input
                    onFocus={handleFocus}
                    className='box-rename'
                    
                    autoFocus={true}
                    Value={context?.cloudPublicUrl}
                    // onKeyUp={handleSubmitForm}
                   
                    readOnly={true}
                  />

                  <div className='selector'>


                  <select className='select'>
                    <option defaultValue={"Admin"}>Admin</option>
                    <option value="View">View</option>
                  </select>
                  
                  <button className='send' oncClick={handleCopy}>Copy</button>
                 

                  
                  </div>

                  </div>
                  </div>

              </div>
            </div>
          </Modal>
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default SharePopUp;
