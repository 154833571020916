import React, { useState, useEffect } from 'react';
import AppContext from './AppContext';
import { useHistory } from 'react-router-dom';
import { uploadFile } from 'react-s3';
import AWS from 'aws-sdk'
import Dexie from 'dexie';
import moment from 'moment';
import config from "../config";


import axios from 'axios';

 let url =  'https://api.bixchat.xyz/cloud/api/file/';
//let url =  'http://localhost:8000/api/file/';
// let urlUser =  'http://localhost:8000/api/user/';

let validateUrl = 'https://api.bixchat.xyz/auth';

let bixChat = 'https://api.bixchat.xyz/message/api/user';

let userID = '12345678';

const S3_BUCKET ='bix-cloud-s3';
const REGION ='ap-south-1';
const ACCESS_KEY ='AKIAZ4HG4UTRKJSBKTIU';
const SECRET_ACCESS_KEY ='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';

const AWSconfig = {
      bucketName: S3_BUCKET,
      region: REGION,
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
}

const AppProvider = props => {
  const history = useHistory();

  const db = new Dexie('BixCloudFiles');

  db.version(1).stores({
    posts: ' title, file',
  });

  db.open().catch(err => {
    // console.log(err.stack || err);
  });


  //for login

  const [loggedFailed, setLoggedFailed] = useState(false);
  const [loginIsLoading, setLoginIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLogged, setIsLogged] = useState(false)

  const [loadData, setLoadData] = useState(1);
  const [dataFiles, setDataFiles] = useState([]);
  const [dataFolders, setDataFolders] = useState([]);
  const [count, setCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [showUploadPopUp, setShowUploadPopUp] = useState(false);
  const [showRecentBar, setShowRecentBar] = useState(false);

  const[UserData,getUserData] = useState([]);

  const [DragPopup, setDragPopup] = useState(false);
  

  const [folderName, setFolderName] = useState('');
  const [ShareSelectedFile, setShareSelectedFile] = useState('');
  const [subfolderName, setSubFolderName] = useState('');
  const [inbuildFileName, setInbuildFileName] = useState('');
  const [renameFileName, setRenameFileName] = useState('');
  

  
  const [selectedUpupFolder, setSelectedUpupFolder] = useState('');
  const [selectedFiles, setSelectedFiles] = useState('');
  const [previewImage, setPreviewImage] = useState( "");

  const [fileId, setFileId] = useState('');
  const [renameFileId, setRenameFileId] = useState('');
  const [shareableUser, setShareableUser] = useState('');
  const [DataPublicFiles,setDataPublicFiles] = useState([]);
  const [DataPublicFolders,setDataPublicFolders] = useState([]);
  const [publicUrl,setPublicUrl] = useState('');
  const [cloudPublicUrl,setCloudPublicUrl] = useState('');
  const [id,setId] = useState('');

  const [uplodingStatus,setUplodingStatus] =useState(false);
  const [folderSelected,setfolderSelected] =useState(false);

  

  const [posts, setPosts] = useState([]);
  const [postTitle, setTitle] = useState('');
  const [dataSubFolders, setDataSubFolders] = useState([]);
  const [level, setLevel] = useState(0);
  let percent = 0;
  const [isUploaded, setIsUploaded] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const [pathRoute, setPathRoute] = useState([])
  const [update, setUpdate] = useState(0);

  const [files,setFiles] =useState([]);

  const [foldersList, setFoldersList] = useState([]);
  const [progress , setProgress] = useState(0);

  const [priority,setPriority] = useState('Admin');

  const [selected, setSelected] = useState('My Files');

const S3_BUCKET ='bix-cloud-s3';
const REGION ='ap-south-1';


AWS.config.update({
    accessKeyId: 'AKIAZ4HG4UTRKJSBKTIU',
    secretAccessKey: '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'
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET},
    region: REGION,
})

// folderName:"Folder 001",
// console.log(findNestedObj(foldersList, 'upFolder', "001003001"));



//login for cloud 

const initFeatures = () => {
  localStorage.setItem('flags', JSON.stringify([
    { name: 'forward', description: 'message forward', active: true },
    { name: 'archived-chats', description: 'Archived chats in more', active: false },
    { name: 'new-label', description: 'new label', active: false },
    { name: 'join-others', description: 'Set as join others', active: false },
    { name: 'save-to-cloud', description: 'Save to bix cloud', active: false },
    { name: 'labelled-messages', description: 'labelled messages in details bar', active: false },
    { name: 'remind-messages', description: 'remind messages in details bar', active: false },
    { name: 'import-from-bix-cloud', description: 'bix cloud import', active: true },
    { name: 'account-settings', description: 'setting page account', active: false },
    { name: 'privacy-and-security-settings', description: 'setting page privacy and security', active: false },
    { name: 'notification-settings', description: 'setting page notification', active: false },
    { name: 'help-and-support-settings', description: 'setting page help and support', active: false },
  ]));
};

const setAccountType = (email) => {
  if (email.includes("@bixchat.xyz")) {
    localStorage.setItem("bixChat_isPrivate", "true")
  } else {
    localStorage.setItem("bixChat_isPrivate", "false")

  }

}

const setUsersToLocalStorage = (publicId, accessToken, fullname, email, image) => {
  let users = JSON.parse(localStorage.getItem("bixChat_users") || "[]");
  const isFound = users.some(value => {
    if (value.publicId === publicId) {
      return true
    }
  })
  if (isFound) {
    users.map(user =>
      user.publicId == publicId ? {
        ...user, accessToken: accessToken,
        email: email,
        fullName: fullname,
        profileImage: image
      } : user
    )

  } else {
    let user = {
      accessToken: accessToken,
      publicId: publicId,
      email: email,
      fullName: fullname,
      profileImage: image
    }
    users.push(user)

  }
  localStorage.setItem("bixChat_users", JSON.stringify(users));


}

const loginClicked = async (userName, password, type) => {

    sendPeriodicallyActions(`login clicked`)
    setLoginIsLoading(true);

    

    let credential = {
      username: type === 'personal' ? `${userName}${config.emailProvider}` : `${userName}`,
      password: password,
    };

    axios({
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
      method: "post",
      baseURL: `${config.apiEndpoints.protocol}${config.apiEndpoints.authServer}`,
      url: "login",
      data: credential,
    })
      .then((response) => {


        if (localStorage.getItem("redirected_from") === "bixCloud") {

          let authToken = response.data["x_access_token"]

          setLoginIsLoading(false);

          // window.location.href = `https://cloud.bixchat.xyz?authToken=${authToken}`
          window.location.href = `localhost?authToken=${authToken}`


        } else {
          initFeatures();
          localStorage.setItem("bixChat_loggedUserId", response.data.user_id);
          localStorage.setItem(
            "bixChat_loggedUserPublicId",
            response.data.public_id
          );
          localStorage.setItem(
            "bixCloud_accessToken",
            response.data["x_access_token"]
          );
          localStorage.setItem("bixChat_isLogged", true);
          setIsLogged(true)
          localStorage.setItem("bixChat_mobileView", 1);
          localStorage.setItem("bixChat_email", response.data.email);
          setAccountType(response.data.email)
          let fullname = response.data.first_name + " " + response.data.last_name
          localStorage.setItem("bixChat_fullname", fullname);
          let image = response.data.profile_pic === null || response.data.profile_pic === 'null' ? "https://bixchat-public-s3.s3.ap-south-1.amazonaws.com/defaults/user_dp.png" : response.data.profile_pic
          localStorage.setItem("bixChat_profileImage", image)
          setUsersToLocalStorage(response.data.public_id, response.data.x_access_token, fullname, response.data.email, image)
          setLoginIsLoading(false);
          getProfile();
          getData();
          if (response.data.is_first_login) {
            history.push({
              pathname: "/reset-password",
            });
          } else {
            history.push({
              pathname: "/",
            });
          }

        }
      })
      .catch((error) => {
        setLoginIsLoading(false);
        setLoggedFailed(true);
      });
  };

  
  

  const sendPeriodicallyActions = ((action) => {



    let data = {
      user_id: localStorage.getItem("bixChat_loggedUserPublicId") || "",
      action: action,
      email: localStorage.getItem("bixChat_email"),
    }

    return new Promise((resolve, reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        method: "post",
        baseURL: `https://api.bixchat.xyz/analytics/record`,
        data: data

      })
        .then((response) => {

          resolve(response)
        })
        .catch((error) => {
          console.log(error);

          reject(error)
        });
    })
  })



const selectFolder = (obj,folders=foldersList) => {
  // addFolderContent("004")
  // console.log("clicked")
  folders.map((folder,id) => {
    if (folder.upFolder===obj) folder.isOpened=true
    if (folder.content.length!==0)   selectFolder(obj,folder.content);
    setFoldersList(foldersList);
   
}
);
setUpdate( update+1)
//    (obj)
}

const addFolderContent = (obj,folders=foldersList) => {
  console.log(obj);
  folders.map((folder,id) => {
    if (folder.id===obj) folder.content=[{
                                                id:"004001",
                                                folderName:"Folder 004 001",
                                                upFolder:obj,
                                                isOpened:false,
                                                content:[ ]
                                              }]
    if (folder.content.length!==0)   addFolderContent(obj,folder.content);
    setFoldersList(foldersList);
}
);
setUpdate( update+1)
}

// if (update<3) addFolderContent("004",foldersList)

const closeFolder = (obj,folders=foldersList) => {
  console.log(obj)
  folders.map((folder,id) => {
    if (folder.upFolder===obj) {
      console.log(folder.folderName)
      folder.isOpened=false
      console.log(folder)
    }
    if (folder.content.length!==0)   closeFolder(obj,folder.content);
    setFoldersList(foldersList);
    // console.log(foldersList)
}
);

setUpdate( update+1)
}

const test = (obj) => {
  // console.log(obj,"sdasadsad")

}



  const auth = async () => {
    let currentUrl = window.location.href;
    let uId = currentUrl.split('uid=')[1];
    // console.log(uId);
    if (uId) {
      let x = await setToken(uId);
      // console.log(x.data.x_access_token)
      if (x.data.x_access_token) {
        await validateToken(x.data.x_access_token)
      }
      else {
        history.push({
          pathname: "/login",
        });
        
      }
    } else {
      let accessToken = localStorage.getItem('bixCloud_accessToken');

      setTimeout(async() => {
        if (accessToken) {
          await validateToken(accessToken)
        }
        else {
          history.push({
            pathname: "/login",
          });
          
        }
      }, 1000);
    
    }
  };

  const validateToken = (accessToken) => {
    return new Promise((resolve, reject) => {
      // console.log(accessToken);
      const options = {
        url: `${validateUrl}/validate`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          'x-access-token': accessToken,
        },
      };
      axios(options)
        .then(response => {
          // console.log(response);

          resolve(response);
        })
        .catch(error => {
          console.log(error);
          localStorage.clear();
          history.push({
            pathname : "/login",
          })
          reject(error);
        });
    });
    
  };

  const setToken = (u_id) => {
    return new Promise((resolve, reject) => {


      const options = {
        url: `${validateUrl}/user_get_token`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
        data: {
          u_id: u_id,

        },
      };
      axios(options)
        .then(response => {
          console.log(response.data != null);
          if (response.data != null) {
            localStorage.setItem('bixCloud_accessToken', response.data.x_access_token);
            let currentUrl = window.location.href;
            let updatedURL = currentUrl.split('/?')[0];
            console.log(updatedURL);
            window.location.href = updatedURL;
          }
          else {
            localStorage.clear();
            history.push({
              pathname : "/login"
            })
          }
          resolve(response);
        })
        .catch(error => {
          localStorage.clear();
          history.push({
            pathname : "/login"
          })
          reject(error);
        });
    });
  };


  let attachment = `${url}download/${fileId}/`

  useEffect(() => {
    auth();
    // userAvailabilityCheck();
    // localStorage.setItem("bixCloud-selectedupFolder", subfolder.id)


    if( localStorage.getItem('bixCloud-selectedupFolder')===""||localStorage.getItem('bixCloud-selectedupFolder')==null)    getData();
    else getSubFolderData();
  
    // if (accessToken) {
     

      
      getProfile();
    //   // getSubFolderData();





    //   let accessTokenFromURL = url.split('authToken=')[1];
    //   console.log(accessTokenFromURL);

    //   if (accessTokenFromURL) {
    //     localStorage.setItem('bixCloud_accessToken', accessTokenFromURL);

    //     let updatedURL = url.split('/?')[0];

    //     window.location.href = updatedURL;
    //     // get user details
    //     getData();
    //     getSubFolderData();
    //   }
    // } else {
    //   // console.log("response");
    //   let url = window.location.href;

    //   // window.location.href = "http://app.bixchat.xyz/login?redirected-from=bixCloud"

    //   let accessTokenFromURL = url.split('authToken=')[1];
    //   console.log(accessTokenFromURL);
    //   if (accessTokenFromURL) {
    //     // console.log(accessTokenFromURL, "accessTokenFromURL")
    //     localStorage.setItem('bixCloud_accessToken', accessTokenFromURL);

    //     // get user details

    //     getData();
    //     getSubFolderData();
    //   } else {
    //     // window.location.href = 'https://app.bixchat.xyz/login?redirected-from=bixCloud';
    //     // window.location.href = 'http://localhost:3000/login?redirected-from=bixCloud';
    //   }
    // }
  }, []);

  const getPostInfo = files => {
    let post = {
      title: postTitle,
      file: files,
    };

    db.posts.put(post).then(async () => {
      //retrieve all posts inside the database
      let allPosts = await db.posts.toArray();
      setPosts(allPosts);
    });
  };

  const logOut = () => {
    localStorage.clear();
    history.push({
      pathname : "/login"
    })
  };

  const loginPush = ()=>{
    history.push({
      pathname : "/login"
    })
  }
 


  const uploadData = files => {
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixCloud_accessToken');
      let fileId = localStorage.getItem('bixCloud-selectedupFolder');
      
      

      const formdata = new FormData();

      formdata.append('size', files[0].size);
      formdata.append('fileName', files[0].name);
      formdata.append('direction', 'image/new1');
      formdata.append('user', userID);
      formdata.append('extention', files[0].type);
      formdata.append('createdDate',moment(files[0].lastModifiedDate).format('YYYY-MM-DD'));
      formdata.append('modifiedDate', files[0].type);
      formdata.append('upFolder',  fileId );
      formdata.append('isDeleted', false);
      
      
     

      const options = {
        url: url,
        method: 'POST',
        data: formdata,
       
        headers: {
          // Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'x-access-token': accessToken,
          //"Access-Control-Allow-Origin": "Accept",
        },
      };

      
    
      
      axios(options)
        .then(response => {
          let key = response.data.id 
          uploadFile(files,key)
          console.log(key);

          console.log(response);
          getSubFolderData();
          setLoadData(1);
          setCount(0);


          
          resolve(response);
        })

      
    });
  };


 
  const uploadFile = (files,key) => {

    const params = {
        ACL: 'public-read',
        Body: files[0],
        Bucket: S3_BUCKET,
        Key: key
    };
    

    myBucket.putObject(params)
      
        .on('httpUploadProgress', (evt) => {
          percent = Math.floor((evt.loaded * 100) /evt.total)
          setPercentage(percent);

          if (evt.loaded === evt.total) {
            setIsUploaded(true);
            setPercentage(0);
            getData();
          }
        })

       
        .send((err) => {
            if (err) console.log(err)
        })     
}


  const dropHandleFiles = (files) => {
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixCloud_accessToken');
      let fileId = localStorage.getItem('bixCloud-selectedupFolder');
      
      

      const formdata = new FormData();

      formdata.append('size', files[0].size);
      formdata.append('fileName', files[0].name);
      formdata.append('direction', 'image/new1');
      formdata.append('user', userID);
      formdata.append('extention', files[0].type);
      formdata.append('createdDate',moment(files[0].lastModifiedDate).format('YYYY-MM-DD'));
      formdata.append('modifiedDate', files[0].type);
      formdata.append('upFolder',  fileId );
      formdata.append('isDeleted', false);
      
      
     

      const options = {
        url: url,
        method: 'POST',
        data: formdata,
       
        headers: {
          // Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'x-access-token': accessToken,
          //"Access-Control-Allow-Origin": "Accept",
        },
      };

      
    
      
      axios(options)
        .then(response => {
          let key = response.data.upload_url.fields.key 
          uploadFile(files,key)
          setDragPopup(false);

          console.log(response);
          getSubFolderData();
          setLoadData(1);
          setCount(0);


          
          resolve(response);
        })

      
    });
    
    
  }

  const uploadFolder = () => {
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixCloud_accessToken');
      let fileId = localStorage.getItem('bixCloud-selectedupFolder');
      
      const formdata = new FormData();

      formdata.append('size', 0);
      formdata.append('fileName', folderName);
      formdata.append('direction', 'image/new1');
      formdata.append('user', userID);
      formdata.append('extention', 'null');
      formdata.append('createdDate', '2021-12-11');
      formdata.append('modifiedDate', '2021-05-04');
      formdata.append('upFolder', fileId);
      formdata.append('isDeleted', false);

      const options = {
        url: url,
        method: 'POST',
        headers: {
          // Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'x-access-token': accessToken,
          // "Access-Control-Allow-Origin": "*",
        },
        data: formdata,
      };
      axios(options)
        .then(response => {
          getSubFolderData();
          // setLoadData(1);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
        setCount(0);
        getData();
    });

    
  };









  const uploadSubFolder = () => {
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixCloud_accessToken');

      const formdata = new FormData();

      formdata.append('size', 0);
      formdata.append('fileName', subfolderName);
      formdata.append('direction', 'image/new1');
      formdata.append('user', userID);
      formdata.append('extention', 'null');
      formdata.append('createdDate', '2021-12-11');
      formdata.append('modifiedDate', '2021-05-04');
      formdata.append('upFolder',localStorage.getItem('bixCloud-selectedupFolder'));
      formdata.append('isDeleted', false);

      const options = {
        url: url,
        method: 'POST',
        headers: {
          // Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'x-access-token': accessToken,
          // "Access-Control-Allow-Origin": "*",
        },

        data: formdata,
      };
      axios(options)
        .then(response => {
          getSubFolderData();
          console.log(response);

          resolve(response);
        })
        .catch(error => {
          reject(error);
        }); 
        getData();
        setCount(0);
    });
    
  };



  const getData = () => {
    console.log("DaTA fROM ROOT INIT")
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixCloud_accessToken');
      console.log(accessToken);

      const options = {
        url: `${url}?root=True`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          // "Access-Control-Allow-Origin": "*",
          'x-access-token': accessToken,
        },
      };
      axios(options)
        .then(response => {
          if (response.data.message) {
          } else {
            let file = response.data || [];
            let folder = [];
            let files = [];
           
            file?.map((value, index) => { 
              if (value.extention === 'null') {
                // setFoldersList(foldersList => [...foldersList, value]);
                folder.push(value); 
                console.log(value)
                
              } else {
                // setFoldersList(foldersList => [...foldersList, value]);
                files.push(value);
                
              }
              return null;
            });
            // console.log(file, "DaTA fROM file")
            setDataFiles(files);
            setDataFolders(folder);
            setUpdate(update+1);
            
      
          }

          resolve(response);
        })
        .catch(error => {
         
          console.log(error);
          reject(error);
        });
    });
  };




  //get shared data//



  const getShared = () => {
    console.log("share trigger")
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixCloud_accessToken');
     
      console.log(accessToken);
      
      
    

      const options = {
        url: `${url}sharedWithMe/`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          // "Access-Control-Allow-Origin": "*",
          'x-access-token': accessToken,
        },
      };
      axios(options)
        .then(response => {
          if (response.data.message) {
          } else {
            let file = response.data || [];
            let folder = [];
            let files = [];
           
            file?.map((value, index) => { 
              if (value.extention === 'null') {
                // setFoldersList(foldersList => [...foldersList, value]);
                folder.push(value); 
                console.log(value)
                
              } else {
                // setFoldersList(foldersList => [...foldersList, value]);
                files.push(value);
                
              }
              return null;
            });
            

            console.log(file, "DaTA fROM share file")
            setDataFiles(files);
            setDataFolders(folder);
            setUpdate(update+1);
            
      
          }

          resolve(response);
        })
        .catch(error => {
         
          console.log(error);
          reject(error);
        });
    });
  };





  const getSubFolderData = e => {
   
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixCloud_accessToken');
      let upFolder = e ? e : localStorage.getItem('bixCloud-selectedupFolder');
   
      const options = {
        url: `${url}?folder=${upFolder}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          // "Access-Control-Allow-Origin": "*",
          'x-access-token': accessToken,
        },
      };
      axios(options)
        .then(response => {
          if (response.data.message) {
          } else {
            let file = response.data;
            let folder = [];
            let files = [];

            // console.log(response.data, "DaTA fROM sUB fOLDER")


            file.map((value, index) => {
              if (value.extention === 'null') {
                folder.push(value);
              } else {
                files.push(value);
              }
              return null;
            });


            setDataFiles(files);
            setDataFolders(folder);
            

          
          }
          

          resolve(response);
        })
        .catch(error => {
          // console.log(error);

          reject(error);
        });
    });

    setLoadData(0);
  };

  const deleteData = files => {
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixCloud_accessToken');

      const formdata = new FormData();

      formdata.append('id', fileId);

      const options = {
        url: url,
        method: 'DELETE',
        headers: {
          // Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'x-access-token': accessToken,
          // "Access-Control-Allow-Origin": "*",
        },

        data: formdata,
      };
      axios(options)
        .then(response => {
          getSubFolderData();
          setLoadData(1);
          getData();

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    setCount(0);
    console.log('delete working');
  };

  const deleteSubFolders = () => {
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixCloud_accessToken');
      // console.log(accessToken , "7")

      const formdata = new FormData();

      // formdata.append('user', userID);
      formdata.append('id', fileId);

      const options = {
        url: url,
        method: 'DELETE',
        headers: {
          // Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'x-access-token': accessToken,
          // "Access-Control-Allow-Origin": "*",
        },

        data: formdata,
      };
      axios(options)
        .then(response => {
          getSubFolderData();
          getData();

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });

    console.log('delete Sub working');
  };

  const renameFile = files => {
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixCloud_accessToken');

      const formdata = new FormData();

      formdata.append('fileName', renameFileName);
      formdata.append('id', fileId);

      const options = {
        url: url,
        method: 'PUT',
        data: formdata,

        headers: {
          // Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'x-access-token': accessToken,
          // "Access-Control-Allow-Origin": "*",
        },
      };
      axios(options)
        .then(response => {
          getSubFolderData();
          setLoadData(1);
          getData();

          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    setCount(0);
  };

  const downloadData = () => {
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixCloud_accessToken');
      // console.log(accessToken , "4")
      const options = {
        url: attachment,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          // "Access-Control-Allow-Origin": "*",
          'x-access-token': accessToken,
        },
      };
      axios(options)
        .then(response => {
          console.log(response.data.url);
          window.open(response.data.url, "_blank");
          resolve(response);
        })
        .catch(error => {
          reject(error);
          getData();
        });
    });
  };

  const setPreviewUrl = () => {
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixCloud_accessToken');
      // console.log(accessToken , "4")
      const options = {
        url: attachment,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
          // "Access-Control-Allow-Origin": "*",
          'x-access-token': accessToken,
        },
      };
      axios(options)
        .then(response => {
          console.log(response.data.url);
          setPreviewImage(response.data.url)
          console.log(previewImage);
          resolve(response);
        })
        .catch(error => {
          reject(error);
          
        });
    });
  };

//public//


  const publicShare = (fileId) => {
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixCloud_accessToken');

      const formdata = new FormData();
      formdata.append('object', fileId);

      

      const options = {
        url: url+"public/",
        method: 'POST',
        data: formdata,

        headers: {
          // Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'x-access-token': accessToken,
          // "Access-Control-Allow-Origin": "*",
        },
      };

      console.log('public sharing triggered ' + "file " + fileId);

      axios(options)
        .then(response => {
          resolve(response);
          
          localStorage.setItem("publicId",response.data.publicId);

          let publicId = response.data.publicId;
          
          urlGen(publicId);
          
          
        })
        .catch(error => {
          reject(error);
        });
        
    });
    
  };

  const urlGen = (publicId) =>{
    setCloudPublicUrl('https://cloud.bixchat.xyz/public/'+publicId);
  }

const getPublicfiles = () =>{
  return new Promise((resolve, reject) => {
    let accessToken = localStorage.getItem('bixCloud_accessToken');

    
    
    
    

    const options = {
      url: url+"public/"+id+"/",
      method: 'GET',
      

      headers: {
        // Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'x-access-token': accessToken,
        // "Access-Control-Allow-Origin": "*",
      },
    };

    console.log('true');

    axios(options)
    
    .then(response => {
      if (response.data.message) {
      } else {
        let publicFile = response.data || [];
        let publicFolder = [];
        let publicFiles = [];
       
        publicFile?.map((value, index) => { 
          if (value.extention === 'null') {
            // setFoldersList(foldersList => [...foldersList, value]);
            publicFolder.push(value); 
            console.log(value)
            
          } else {
            // setFoldersList(foldersList => [...foldersList, value]);
            publicFiles.push(value);
            
          }
          return null;
        });
        console.log("DaTA fROM file")
        setDataPublicFiles(publicFiles);
        setDataPublicFolders(publicFolder);
        setUpdate(update+1);
        console.log(response)
        
  
      }

      resolve(response);
    })
      .catch(error => {
        reject(error);
      });
      
  });
}

//__________________________________________________________________________//



  const shareFile = (fileId) => {
    return new Promise((resolve, reject) => {
      let accessToken = localStorage.getItem('bixCloud_accessToken');

      const formdata = new FormData();

      formdata.append('object',fileId );
      formdata.append('user', shareableUser);
      formdata.append('priority',priority);

      console.log('file and email  - ' + fileId + ' , ' +shareableUser)

      const options = {
        url: url+"share/",
        method: 'POST',
        data: formdata,

        headers: {
          // Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'x-access-token': accessToken,
          // "Access-Control-Allow-Origin": "*",
        },
      };

      console.log('shareu ' + shareableUser)

      axios(options)
        .then(response => {
          getSubFolderData();
          setLoadData(1);
          getData();

          console.log(response.data)
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
    setCount(0);
  };

  const generateFolderPath = ((e, type) => {

    if (type === "rootClick") {
      setPathRoute([e])
    } else if (type === "SubClick") {

      let existPath = pathRoute

      existPath.push(e)

    }

  });

  
  const getProfile = () => {
    let accessToken = localStorage.getItem('bixCloud_accessToken');
    return new Promise((reject) => {
      axios({
        headers: {
          "Access-Control-Allow-Origin": "*",
          'x-access-token': accessToken,
        },
        method: "GET",
        baseURL: bixChat,
      })
        .then((response) => {
            localStorage.setItem("full_name", response.data.full_name);
            localStorage.setItem("Email", response.data.email);
            localStorage.setItem("profilePicture", response.data.profile_image);
            
         
            console.log(response.data);
        })
        .catch((error) => {
          reject(error)
          
          
        });
    })


  };

  
  const handleClick = () => {
    if (typeof props.actionParam !== "undefined")  props.action(props.actionParam||'');
    else if (typeof props.action !== "undefined")  props.action(); 
    else console.log("Clicked !!!")
  }






  

 
    
   








  
  

  return (
    <AppContext.Provider
      value={{

        uplodingStatus,
        setUplodingStatus,
        folderSelected,
        setfolderSelected,

        loggedFailed,
        setLoggedFailed,

        loginIsLoading,
        setLoginIsLoading,

        isLoggedIn,
        setIsLoggedIn,

        isLogged,
        setIsLogged,

        loginClicked,


        publicShare,
        DataPublicFiles,
        setDataPublicFiles,
        publicUrl,
        setPublicUrl,
        cloudPublicUrl,
        setCloudPublicUrl,
        getPublicfiles,
        
        id,
        setId,


        DataPublicFolders,
        setDataPublicFolders,


        setPriority,
        priority,

        selected,
        setSelected,


        dropHandleFiles,
        files,
        setFiles,
        
        setProgress,
        progress,

        DragPopup,
        setDragPopup,

        folderName,
        setFolderName,

        handleClick,

        subfolderName,
        setSubFolderName,

        loadData,
        setLoadData,
        dataFiles,
        setDataFiles,
        dataFolders,
        setDataFolders,
        getData,
        uploadData,

        
        UserData,
        getProfile,

        uploadFolder,
        deleteData,

        getShared,

        loader,
        setLoader,

        showRecentBar,
        setShowRecentBar,

        count,
        setCount,

        getPostInfo,

        postTitle,
        setTitle,

        uploadSubFolder,
        getSubFolderData,

        
        

        dataSubFolders,
        setDataSubFolders,

        selectedUpupFolder,
        setSelectedUpupFolder,

        selectedFiles,
        setSelectedFiles,

        fileId,
        setFileId,

        deleteSubFolders,
        logOut,
        loginPush,

        level,
        setLevel,

        isUploaded,
        setIsUploaded,

        renameFileName,
        setRenameFileName,

        shareableUser,
        setShareableUser,
        setShareSelectedFile,

        renameFileId,
        setRenameFileId,

        renameFile,
        inbuildFileName,
        setInbuildFileName,
        downloadData,
        setPreviewUrl,

        percent,
        percentage,
        setPercentage,
        showUploadPopUp,
        setShowUploadPopUp,

        generateFolderPath,
        pathRoute,

        attachment,

        selectFolder,
        closeFolder,

        foldersList,
        setFoldersList,

        previewImage,
        setPreviewImage,

        update,
         setUpdate,
         test,
         addFolderContent,
         shareFile





      }}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
