import React, { useContext, useState, useRef } from 'react';
import Modal from 'react-modal';
import Folder from '../Folder';
import './PopUp.css';
import Store from '../context';
import AppContext from '../../context/AppContext';
import FolderAdd from '../../Assets/Icons/folder-add.svg';
import close from '../../Assets/Icons/close.svg';
import danger from '../../Assets/Icons/danger.svg';


// import {v4 as uuidv4} from "uuid";
const customStyles = {
  content: {
    top: '52%',
    left: '52%',
    right: 'auto',
    height: '25vh',
    width: '27%',
    bottom: 'auto',
    borderRadius: '10px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderTop: '15px solid #C93737',
    overflowY: 'hidden',
  },
};

const DeletePopUp = props => {
  let subtitle;
  const [loader, setLoader] = useState(true);
  const { dispatch } = useContext(Store);

  function openModal() {
    setLoader(true);
  }

  function closeModal() {
    setLoader(false);
  }
  function afterOpenModal() {
    // style.color = 'rgb(15, 15, 15)';
  }

  return (
    <AppContext.Consumer>
      {context => (
        <div className='new-folder-mod'>
          <Modal
            isOpen={loader}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel='Example Modal'
            ariaHideApp={false}>
            <div className='row'>
              <div className='col-md-12'>
                <br />
                <div className='input-group'>
                  <img
                    className='close-button-delete'
                    src={close}
                    onClick={() => {
                        props.setDeletePopUp(false)
                     
                    }}
                  />

                  <img
                    className='danger'
                    src={danger}
                    onClick={() => {
                    }}
                  />
                  <div className='popup-up-delete'>
                    <h2 className='modal-name-delete'>
                      {' '}
                      Do you want to delete this file?{' '}
                    </h2>
                  </div>

                  <div className='input-group-append'>
                    <button
                      className='button-popup1-delete'
                      onClick={() => {
                        props.setDeletePopUp(false)
                      }}>
                      No
                    </button>
                    <button
                      className='button-popup2-delete'
                      onClick={(files) => {
                      
                        context?.deleteData(files);
                        context?.deleteSubFolders();
                        props.setDeletePopUp(false)

                      }}>
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default DeletePopUp;
