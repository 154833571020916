import React, { useEffect, useState, useRef, useContext } from "react";
import './FilePreview.css';
import AppContext from '../../context/AppContext';

// import ReactPlayer from 'react-player'

import image from "../../Assets/Icons/Iconly-Light-Document.svg";
import close from "../../Assets/Icons/cross-square-button.svg";
import clouddownload from "../../Assets/Icons/clouddownload.svg";
import ReactPlayer from 'react-player';
import {Document,Page, pdfjs} from 'react-pdf';
import down_arrow from "../../Assets/Icons/down_arrow.svg"
import zoomOut from "../../Assets/Icons/zoom_out.svg";
import zoomIn from "../../Assets/Icons/zoom_in.svg";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



// import AudioComponent from "../AudioComponent/AudioComponent";




const FilePreview = props => {

  let context = useContext(AppContext)

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
    console.log("back")
  }

  function nextPage() {
    changePage(1);
    console.log("next")
  }
 
  function scaleUp () {
    console.log("scaleUp")
    setScale(scale +1);
  }
  function scaleDown(){
    setScale(scale -1);
    console.log("scaleDown")
  }

  
  
  

  const previewEngine =() => {
    if(props.detailsBarFileName.toLowerCase().includes("mp4")){
      
      return(<ReactPlayer
        className="video-preview"
        url={context.previewImage}
        width={1080}
        height={550}
        controls={true}
        loop={false}
        

        >

        </ReactPlayer>)


    }else if (props.detailsBarFileName.toLowerCase().includes("pdf")){
      return(  <div className="pdf-container">
        <Document
          file={context.previewImage}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page 
          pageNumber={pageNumber}
          width="500"
          
          scale={scale}
           />
        </Document>
        <div className="control">
         
          <img
            src={down_arrow}
            disabled={pageNumber <= 1}
            onClick={previousPage}
            className="previousPage"
            alt="PreviousPage"
          >
            
          </img>
          <div className="pageText">
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </div>
          <img
            src={down_arrow}
            disabled={pageNumber <= numPages}
            onClick={nextPage}
            className="nextPage"
            alt="NextPage"
          >
          
          </img>
        </div>
        <div className="zoom_control">
          {/* <img src={zoomOut} alt="" className="zoomOut" onClick={scaleDown}/>
          <img src={zoomIn} alt="" className="zoomIn" onClick={scaleUp}/> */}
        </div>
      </div>)


    }else if (props.detailsBarFileName.toLowerCase().includes("jpg")){
      return (<img className="image-preview" src={context.previewImage} alt={props.detailsBarFileName} />)
    }else if (props.detailsBarFileName.toLowerCase().includes("png")){
      return (<img className="image-preview" src={context.previewImage} alt={props.detailsBarFileName} />)
    }
  }






  return (
    <AppContext.Consumer>
      {context => (
     <div className="Image_Preview">
     <div className="image-preview-container">
       <div className="header">
         <div className="right-container">
           <div className="img-cont">
             <img  alt="" src={image} className="image" />
           </div>
           <div className="text">
           <div className="attachment-name">{props.detailsBarFileName}</div>

         

            
           </div>
         </div>
         
         <div className="left-container ">
           <div className="image-container">
             {context?.dataFiles.map((image) => {
                  
                  
               return (
                 <a className="download-img-a">
                   <img alt="" src={clouddownload} className="image-download"
                   onClick={() => {
                    context?.downloadData();
                  }} />
                 </a>
               );
             })}

             <img
               alt=""
               src={close}
               className="imageClose"
               onClick={() => {
                 props.setFilePreview(false);
               }}
             />
           </div>

           
         </div>

         
       </div>

       <div className="main-preview">
           
              <div className="image-content">
                
                
            
              {previewEngine()}

       
             </div> 
             
           </div>
  
     </div>
   </div>
   
      )}
      
    </AppContext.Consumer>
  );
};
export default FilePreview;
