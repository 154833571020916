import React from 'react'
import { useEffect } from 'react';
import './Input.css'

const Input = ((props) => {
  return (
         <input
         style={props.customStyles|| ''}
         className={props.styles ||''}
         type={props.type||''}
         placeholder={props.placeholder||''}
         name={props.name||''}
         onClick={() => {
          if (typeof props.actionParam !== "undefined")  props.action(props.actionParam||'');
          else if (typeof props.action !== "undefined")  props.action(); 
          else console.log("Clicked !!!")
        }}
        value={props.text||''}
       />
 );
})

export default Input
