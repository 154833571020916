import React, { useState, useRef, useEffect, useContext } from 'react';
import './RightSide.css';
import Modal, { contextType } from 'react-modal';
import ReactiveButton from 'reactive-button';
import SubFolder from '../ModalComponents/SubFolder';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UploadIcon from '../../Assets/Icons/up-arrow.svg';
import AppContext from '../../context/AppContext';
import more from '../../Assets/Icons/more.svg';
import downarrow from '../../Assets/Icons/arrow-right.svg';
import viewtypeone from '../../Assets/Icons/row-vertical.svg';
import viewtypetwo from '../../Assets/Icons/element-3.svg';
import searchIcon from '../../Assets/Icons/search.svg';
import middleFolder from '../../Assets/Icons/foldermiddle.svg';
import addFolder from '../../Assets/Icons/addcircle.svg';
import SubFolderIcon from '../../Assets/Icons/folder-right.svg';
import animationData from '../../Assets/Jsons/13525-empty (1).json';
import PopRename from '../ModalComponents/RenamePopUp';
import PopDelete from '../ModalComponents/DeletePopUp';
import PopShare from '../ModalComponents/SharePopUp';
import FilePreview from '../FilePreviewComponent/FilePreview';
import TopBar from './topBar';


import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ContentCopy from '@mui/icons-material/ContentCopy';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ImgIcon from '../../Assets/Icons/newdoc.svg';
import closeProgress from '../../Assets/Icons/close.png';
import tickProgress from '../../Assets/Icons/tick.png';

import Button from '../../Components/Commen/Button/Button';
import popup from '../Upload_popup/upload_popup';
import Popup from '../Upload_popup/upload_popup';

import DragDropPopUp from '../DragDrop/DragDrop'




const customStyles = {
  content: {
    borderLeft: '13px solid #3799F4',
    overflowY: 'hidden',
    top: '89%',
    left: '85%',
    right: 'auto',
    height: '13vh',
    width: '27%',
    bottom: 'auto',
    borderRadius: '10px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const Files = props => {
  const value = useContext(AppContext);

  const [state, setState] = useState('idle');

  const uploadImage = useRef(null);


  const openShareModal =()=>{
    if(sharePopUp == true){
      setSharePopUp(false)
    }
    else if (sharePopUp == false){
      setSharePopUp(true)
    }
  }

  const [showFiles, setShowFiles] = useState(true);

  const [searchData, setSearchData] = useState([]);
  const [description, setDescription] = useState('');
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [contextMenu, setContextMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sharePopUp, setSharePopUp] = useState(false);

  const [search, setSearch] = useState(0);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleClickPopUp = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const renderMenu = (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}>
      <Paper
        sx={{
          width: 160,
          maxWidth: '100%',
          backgroundColor: '#F0F0F6',
          borderRadius: '2px',
          boxShadow: 'none',
        }}>
        <MenuList>
          <MenuItem 
          
          onClick={() => {
            if (props.filePreview) {
              props.setFilePreview(false);
            } else {
              setAnchorEl(null);
              value?.setPreviewUrl();
              props.setFilePreview(true);
            }
          }}>
            <ListItemIcon>
              <VisibilityOutlinedIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Preview</ListItemText>
            {/* <Typography
            variant='body2'
            color='text.secondary'>
            ⌘X
          </Typography> */}
          </MenuItem>
          <MenuItem
            onClick={() => {
              value?.downloadData();
            }}>
            <ListItemIcon>
              <FileDownloadOutlinedIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Download</ListItemText>
          </MenuItem>
          <MenuItem 
            onClick={() =>  {
              openShareModal()
              if (sharePopUp) {
                // setSharePopUp(false);
                props.setShareSelectedFile(false);
                
              } else {
                setAnchorEl(null);
                // setSharePopUp(true);
                props.setShareSelectedFile(true);
              }}
            }>
            <ListItemIcon>
              <ShareOutlinedIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Share</ListItemText>
          </MenuItem>



          <MenuItem
            onClick={() => {
              if (props.renameSelectedFile) {
                props.setRenameSelectedFile(false);
              } else {
                setAnchorEl(null);
                props.setRenameSelectedFile(true);
              }
            }}>
            <ListItemIcon>
              <DriveFileRenameOutlineOutlinedIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Rename</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              if (deletePopUp) {
                setDeletePopUp(false);
              } else {
                setAnchorEl(null);
                setDeletePopUp(true);
              }
            }}>
            <ListItemIcon>
              <DeleteOutlineOutlinedIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (props.showDetails) {
                props.setShowDetails(false);
                minwidth();
                setAnchorEl(null);
              } else {
                setAnchorEl(null);
                showDetailsBar();
                maxwidth();
              }
            }}>
            <ListItemIcon>
              <InfoOutlinedIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>Details</ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
    </Popover>
  );

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  document.addEventListener('readystatechange', event => { 

    // When HTML/DOM elements are ready:
    if (event.target.readyState === "complete") {   //does same as:  ..addEventListener("DOMContentLoaded"..
        value.getData();
        
    }

   
    
});

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      // console.log(windowDimensions + "diam")
      
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (props.folderSelected) {
      value.setShowUploadPopUp(true);
      props.setUplodingStatus(true);
      props.setFolderSelected(true);
      console.log('working fine');
      
    } else {
      if (value.isUploaded) {
        props.setUplodingStatus(true);
        value.setIsUploaded(false);
        console.log('working fine 2');
        

        setTimeout(() => {
          value.setShowUploadPopUp(false);
          props.setFiles([]);
          props.setUplodingStatus(false);
          props.setFolderSelected(false);
        }, 3000);
      }
    }
  }, [value.isUploaded]);

  // useEffect(() => {

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();

    console.log(e, 'eeeee');
  };
  const handleDrop = async ev => {
    ev.preventDefault();
    ev.stopPropagation();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          var file = ev.dataTransfer.items[i].getAsFile();
          console.log('... file[' + i + '].name = ' + file.name);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        console.log(
          '... file[' + i + '].name = ' + ev.dataTransfer.files[i].name
        );
      }
    }

    // console.log(e , "jo");
    try {
      // await handleFile(e);

      value?.setShowUploadPopUp(true);
      value?.setShowRecentBar(true);
    } catch (error) {
      console.log(error);
      console.log('error happens');
    }
  };

  const onImageClick = () => {
    uploadImage.current.click();
    props.setFolderSelected(false);
  };

  const handleFile = e => {
    console.log(e.target);
    // for (let file of e.target.files) {
    //   if (!files.includes(file)) {
    //     setFiles([...files, ...e.target.files]);9
    //   }
    // }

    // console.log(e.target.files, 'event');

    return new Promise((resolve, reject) => {
      let filesPromise = [...e?.target?.files].map(file => {
        if (!props.files.includes(file)) {
          props.setFiles([...props.files, ...e.target.files]);
        }
      });

      Promise.all(filesPromise)
        .then(() => {
          console.log('complete');
          resolve('State Updated');
        })
        .catch(() => {
          reject('Stage Failed');
        });
    });

    // for (let file of e.target.files) {
    //   if (!files.includes(file)) {
    //     setFiles([...files, ...e.target.files]);
    //   }
    // }
  };
  // function afterOpenModal() {
  //   // references are now sync'd and can be accessed.
  //   subtitle.style.color = 'rgb(15, 15, 15)';
  // }
  // console.log(windowDimensions.width)
  const onClickHandler = () => {
    setState('loading');
    setTimeout(() => {
      setState('success');
    }, 5000);
    setShowFiles(true);

    // console.log('Uploading Data' + props.fileName);
  };
  useEffect(() => {
    if (props.files.length > 0) {
      value?.uploadData(props.files);
      value?.setTitle(props.files[0]?.name);
      value?.getPostInfo();
      value?.getSubFolderData();
    }
  }, [props.files]);
  // function handleClick(e, data) {
  //   // console.log(data.foo);
  // }

  const showDetailsBar = () => {
    props.setShowDetails(true);
  };

  // const showDetailsBar1 = () => {
  //   props.setShowDetails(false);
  // };

  const minwidth = () => {
    props.setMiddleMaxWidth(true);
  };

  const maxwidth = () => {
    props.setMiddleMaxWidth(false);
  };

  useEffect(() => {
    if (props.uplodingStatus) {
      value.setPercentage(0);
    }
  }, [props.uplodingStatus]);
  return (
    <AppContext.Consumer>
      {context => (
        
        <div className='files-main-wrapper'
        onDragEnter={() => context?.setDragPopup(true)}
        >
          {' '}
          <div className='middle'>
          <TopBar
        
        onDragEnter={() => context?.setDragPopup(true)}
        showRightSide={props.showRightSide}
        setShowRightSide={props.setShowRightSide}
        showImagesFolder={props.FoldershowImagesFolder}
        setShowImagesFolder={props.setShowImagesFolder}
        root={props.root}
        setRoot={props.setRoot}
        mainRoot={props.mainRoot}
        showDetails={props.showDetails}
        setShowDetails={props.setShowDetails}
        middleMaxWidth={props.middleMaxWidth}
        setMiddleMaxWidth={props.setMiddleMaxWidth}
        createSubFolder={props.createSubFolder}
        setCreateSubFolder={props.setCreateSubFolder}
        showSubFolder={props.showSubFolder}
        setShowSubFolder={props.setShowSubFolder}
        isUploaded={context.isUploaded}
        anchorEl={props.anchorEl}
        setAnchorEl={props.setAnchorEl}
        renameSelectedFile={props.renameSelectedFile}
        setRenameSelectedFile={props.setRenameSelectedFile}
        shareSelectedFile={props.shareSelectedFile}
        setShareSelectedFile={props.setShareSelectedFile}
        detailsBarType={props.detailsBarType}
        setDetailsBarType={props.setDetailsBarType}
        detailsBarFileName={props.detailsBarFileName}
        setDetailsBarFileName={props.setDetailsBarFileName}
        detailsBarModified={props.detailsBarModified}
        SetdetailsBarModified={props.SetdetailsBarModified}
        detailsBarCreatedDate={props.detailsBarCreatedDate}
        setDetailsBarCreatedDate={props.setDetailsBarCreatedDate}
        detailsBarLocation={props.detailsBarLocation}
        setDetailsBarLocation={props.setDetailsBarLocation}
        uplodingStatus={props.uplodingStatus}
        setUplodingStatus={props.setUplodingStatus}
        files={props.files}
        setFiles={props.setFiles}
        folderSelected={props.folderSelected}
        setFolderSelected={props.setFolderSelected}
        filePreview={props.filePreview}
        setFilePreview={props.setFilePreview}
        
          />





          
          <DragDropPopUp trigger={context?.DragPopup} setTrigger={context?.setDragPopup}
          files={props.files}
          setFiles={props.setFiles}/>
            <div className='middle-top'>
              

              <h2 className='middle-folder'>
                {context?.pathRoute.map((singleRoute, i) => {
                  return <div>{singleRoute + '   >   '}</div>;
                })}
              </h2>
            </div>

            <div className='parent-div'>
              
            
              {showFiles ? (
                
                <div>
                  

                 

           

                  {/* {localStorage.getItem('bixCloud-selectedupFolder') ? ( */}
                  {true ? (
                    context?.dataFolders.length > 0 ||
                    context?.dataFiles.length > 0 ? (
                      <div
                        className='uploaded-Files'
                        onDrop={e => handleDrop(e)}
                        onDragOver={e => handleDragOver(e)}>
                        <div className='files-Wrapper'>
                     
                          { context?.dataFolders.map(subfolder => {
                            
                            return (
                              <div
                                className={`${
                                  subfolder.id === context?.selectedFiles
                                    ? 'sub-folder-selected'
                                    : 'img-div'
                                } `}>
                                <img
                                  src={more}
                                  alt=''
                                  className='file-more'
                                  onClick={event => {
                                    handleClickPopUp(event);
                                    context?.setFileId(subfolder.id);
                                    localStorage.setItem("bixCloud-selectedupFolder", subfolder.id)
                                    context?.setInbuildFileName(
                                      
                                      `${subfolder.file_name}`
                                    );
                                  }}
                                />

                                

                                <div
                                  className='folder-icon-wrapper'
                                  onClick={() => {
                                    context?.setSelectedFiles(subfolder.id);
                                  }}
                                  onDoubleClick={() => {
                                    // props.setRoot(file);
                                    props.setShowImagesFolder(true);

                                    localStorage.setItem(
                                      'bixCloud-selectedupFolder',
                                      subfolder.id
                                    );
                                    context?.generateFolderPath(
                                      subfolder.file_name,
                                      'SubClick'
                                    );

                                    context?.getSubFolderData();
                                    props.setRoot(subfolder.file_name);
                                    props.setShowSubFolder(true);
                                  }}>
                                  <img
                                    className='folder-icon'
                                    src={SubFolderIcon}
                                    alt=''
                                  />
                                  {/* <div className='file-tag'>Folder</div> */}
                                  <div className='file-name-div'>
                                    <p className='file-namee'>
                                      {' '}
                                      {subfolder.file_name}
                                    </p>
                                    <p className='last-opened'> 2 hours ago</p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          
                          {/* {console.log( context?.dataFiles)} */}

                          
                          {context?.dataFiles.map(imagefile => {
                            return (
                              <div
                                onClick={() => {
                                  context?.setSelectedFiles(imagefile.id);
                                }}
                                onDoubleClick={() => {
                                  props.setRoot(imagefile.file_name);
                                }}
                                className={`${
                                  imagefile.id === context?.selectedFiles
                                    ? 'sub-folder-selected'
                                    : 'img-div'
                                } `}>
                                {/* <p className='img'> {file.description}</p> */}
                                <img
                                alt=''
                                  src={more}
                                  className='file-more'
                                  onClick={event => {
                                    handleClickPopUp(event);
                                    let updatedExtention =
                                      imagefile.extention.split('image/')[1];

                                    context?.setFileId(imagefile.id);
                                    context?.setInbuildFileName(
                                      `${imagefile.file_name}`
                                    );

                                    // context?.setInbuildFileName(
                                    //   `${imagefile.file_name}.${updatedExtention}`
                                    // );
                                    props.setDetailsBarType(updatedExtention);
                                    props.setDetailsBarFileName(
                                      imagefile.file_name
                                    );
                                    props.SetdetailsBarModified(
                                      imagefile.modifiedDate
                                    );
                                    props.setDetailsBarCreatedDate(
                                      imagefile.createdDate
                                    );
                                    props.setDetailsBarLocation(
                                      imagefile.direction
                                    );

                                    console.log(props.detailsBarFileName);
                                    console.log(props.detailsBarModified);
                                    console.log(props.detailsBarType);
                                    
                                  }}
                                />
                                {renderMenu}
                               

                                <div className='file-icon-wrapper'>
                                  <img
                                    className='img'
                                    // src={`http://ec2-13-233-101-254.ap-south-1.compute.amazonaws.com:8000/api/file/thumbnail/${imagefile.id}`}
                                    alt=''
                                  />
                                  <img className='img' src={ImgIcon} width='150px' height='65px' alt='' />
                                  <div className='file-tag'>
                                    {imagefile.extention.split('/')[1]}
                                  </div>
                                  <div className='file-name-div'>
                                    <p className='file-namee'>
                                      {imagefile.file_name}
                                    </p>
                                    <p className='last-opened'></p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className='lottie'>
                          <Lottie
                            options={defaultOptions}
                            height={200}
                            width={200}
                          />
                        </div>

                        <div>
                          <p className='empty-folder'>This folder is empty</p>
                          <p className='empty-folder2'>Drop Files Here </p>
                        </div>
                      </div>
                      
                    )
                  ) : (
                    <div>
                      <div className='lottie'>
                        <Lottie
                          options={defaultOptions}
                          height={200}
                          width={200}
                        />
                      </div>

                      <div>
                        <p className='empty-folder'>Welcome to Bix Cloud</p>
                        <p className='empty-folder2'>Good to see you here</p>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
           <div>
            <Modal
              isOpen={context.loader}
              // onAfterOpen={afterOpenModal}
              onRequestClose={() => {
                context?.setLoader(false);
              }}
              style={customStyles}
              contentLabel='Example Modal'
              ariaHideApp={false}>
              <div>
                {props.files.map(file => {
                  return (
                    <div className='img-div-modal'>
                      <div className='progress-modal'>
                        <div className='progress-modal-left'>
                          <img
                            className='img-modal-progress'
                            src={ImgIcon}
                            alt=''
                          />
                          <p className='file-tag-popup-progress'>JPEG</p>
                        </div>
                      </div>

                      <div className='file-name-modal'>
                        <p className='file-namee-progress'> {file.name}</p>

                        {props.uplodingStatus ? (
                          <div id='hello' className='progress-status-div'>
                            <p className='uploading-status'>
                              uploaded
                              <img
                                className='close-progress-icon'
                                src={tickProgress}
                              />
                            </p>
                          </div>
                        ) : (
                          <div id='hello' className='progress-status-div'>
                            <p className='uploading-status'>
                              uploading...{' '}
                              <img
                                className='close-progress-icon'
                                src={closeProgress}
                              />
                            </p>
                          </div>
                        )}
                        <div
                          id='myBar'
                          style={{
                            height: '2px',
                            width: context.percentage - 25 + '%',
                            backgroundColor: '#65B4FE',
                            marginLeft: '20%',
                          }}></div>
                      </div>

                      <div></div>
                    </div>
                  );
                })}
              </div>
            </Modal>
          </div> 
          {props.createSubFolder ? (
            <SubFolder
              setShowFolders={props.setShowFolders}
              createSubFolder={props.createSubFolder}
              setCreateSubFolder={props.setCreateSubFolder}
            />
          ) : (
            ''
          )}
          {deletePopUp ? (
            <PopDelete
              deletePopUp={deletePopUp}
              setDeletePopUp={setDeletePopUp}
            />
          ) : (
            ''
          )}
          {sharePopUp ? (
            <PopShare
            sharePopUp={sharePopUp}
              setPopUp={setSharePopUp}
              openShareModal={openShareModal}
              shareSelectedFile={props.shareSelectedFile}
              setShareSelectedFile={props.setShareSelectedFile}
              selectedFile = {context?.selectedFiles}
              
            />
          ) : (
            ''
          )}
          {props.renameSelectedFile ? (
            <PopRename
              renameSelectedFile={props.renameSelectedFile}
              setRenameSelectedFile={props.setRenameSelectedFile}
            />
          ) : (
            ''
          )}
          {props.filePreview ? (
            <FilePreview
            filePreview={props.filePreview}
            setFilePreview={props.setFilePreview}
            detailsBarType={props.detailsBarType}
            detailsBarFileName={props.detailsBarFileName}
            detailsBarModified={props.detailsBarModified}
            detailsBarCreatedDate={props.detailsBarCreatedDate}
            detailsBarLocation={props.detailsBarLocation}
            
           
            />
          ) : (
            ''
          )}
        </div>
      )}
    </AppContext.Consumer>
  );
};
export default Files;
