import React, { useState, useEffect } from "react";
import config from "../../config";
import AppContext from "../../context/AppContext";
import userIcon from "../../Assets/Icons/vuesax-linear-eye-slash.svg";
import pwdIcon2 from "../../Assets/Icons/vuesax-linear-eye.svg";
import user from "../../Assets/Icons/vuesax-linear-user.svg";
import key from "../../Assets/Icons/vuesax-linear-key.svg";
import lock from "../../Assets/Icons/vuesax-linear-lock.svg";
import location from "../../Assets/Icons/vuesax-linear-location.svg"


import "./UsernameInputStyles.scss";



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const LoginInput = (props) => {
  const [showPwd, setShowPwd] = useState(false);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getIcon = (type) => {
    if (type === "username") {
      return !props.isUsernameFocus && props.value === "" ? (
        <img src={user} className="icon1" />
      ) : (
        ""
      );
    } else if (type === "password") {
      return !props.isPwdFocus && props.value === "" ? (
        <img src={key} className="icon1" />
      ) : (
        ""
      );
    } else if (props.name === "confirmPass") {
      return !props.isPwdFocus && props.value === "" ? (
        <img src={lock} className="icon1" />
      ) : (
        ""
      );
      
    }

     else if (type === "fname") {
            return (
                !props.isUsernameFocus && props.value === ""? <img src={user} className="icon1" /> : "")
        }
        else if (type === "lname") {
            return (
                !props.isPwdFocus && props.value === ""? <img src={user} className="icon1" /> : "")
        }
        else if (type === "address") {
            return (
                !props.isAddressFocus && props.value === ""? <img src={location} className="icon1" /> : "")
        }
        else if (type === "designation") {
            return (
                !props.isDesFocus && props.value === ""? <img src={user} className="icon1" /> : "")
        }
  };

  const focusClicked = () => {
    if (windowDimensions.width <= 768) {
      const slideShowSelector = document.querySelector(".slideshow-container");
      const signupContainerSelector = document.querySelector(
        "div._SignupContainer > div > div"
      );
      const loginContainerSelector = document.querySelector(
        "div._loginInnerContainerLeft > div"
      );
      const forgetPasswordContainerSelector = document.querySelector(
        "div.ForgotPwdInnercontainerLeft > div"
      );

      if (slideShowSelector) {
        slideShowSelector.style.display = "none";
      }

      if (signupContainerSelector) {
        signupContainerSelector.style.height = "100vh";
      }

      if (loginContainerSelector) {
        loginContainerSelector.style.height = "100vh";
      }

      if (forgetPasswordContainerSelector) {
        forgetPasswordContainerSelector.style.height = "100vh";
      }
    }
  };

  const outFocused = () => {
    if (windowDimensions.width <= 768) {
      const slideShowSelector = document.querySelector(".slideshow-container");
      const signupContainerSelector = document.querySelector(
        "div._SignupContainer > div > div"
      );
      const loginContainerSelector = document.querySelector(
        "div._loginInnerContainerLeft > div"
      );
      const forgetPasswordContainerSelector = document.querySelector(
        "div.ForgotPwdInnercontainerLeft > div"
      );

      if (slideShowSelector) {
        slideShowSelector.style.display = "block";
      }

      if (signupContainerSelector) {
        signupContainerSelector.style.height = "55vh";
      }

      if (loginContainerSelector) {
        loginContainerSelector.style.height = "55vh";
      }

      if (forgetPasswordContainerSelector) {
        forgetPasswordContainerSelector.style.height = "55vh";
      }
    }
  };

  return (
    <AppContext.Consumer>
      {(context) => (
        <div>
          {" "}
          <div className="input-container">
            {getIcon(props.name)}

            <input
              type={showPwd ? "text" : props.type}
              className="login-input-box"
              name={props.name}
              id="fname"
              placeholder={props.placeholder}
              onChange={props.onChange}
              value={props.value}
              onFocus={() => {
                props.onFocus();
                focusClicked();
              }}
              onBlur={() => {
                props.onBlur();
                outFocused();
              }}
              onPaste={props.onPaste}
            />

            {props.from === "register"? (
              props.isUsernameFocus ||
              (props.name === "username" && props.value !== "") ? (
                <span for="fname" className="fname">
                  @{config.emailText}
                </span>
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {props.name === "password" ? (
              <span
                for="fname"
                className="icon"
                onClick={() => {
                  if (showPwd) {
                    setShowPwd(false);
                  } else {
                    setShowPwd(true);
                  }
                }}
              >
                {showPwd ? <img src={pwdIcon2} /> : <img src={userIcon} />}
              </span>
            ) : (
              ""
            )}

            {props.name === "confirmPass" ? (
              <span
                for="fname"
                className="icon"
                onClick={() => {
                  if (showPwd) {
                    setShowPwd(false);
                  } else {
                    setShowPwd(true);
                  }
                }}
              >
                {showPwd ? <img src={pwdIcon2} /> : <img src={userIcon} />}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default LoginInput;
