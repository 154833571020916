const developemnt = {
    apiEndpoints: {
      protocol: "https://",
      authServer: "api.bixchat.xyz/message/api",
    },
    emailProvider: "@bixchat.xyz",
    emailText: "bixchat.xyz",
  };
  
  const staging = {
    apiEndpoints: {
      protocol: "https://",
      authServer: "api.bixchat.xyz/message/api",
    },
    emailProvider: "@bixchat.xyz",
    emailText: "bixchat.xyz",
  };
  
  const production = {
    apiEndpoints: {
      protocol: "https://",
      authServer: "api.bixmessage.com/message/api",
    },
    emailProvider: "@bixmessage.com",
  
    emailText: "bixchat.xyz",
  };
  
  let basicExport = developemnt; // developemnt || staging || production
  
  if (process.env.REACT_APP_ENV === "dev") {
    basicExport = developemnt;
  } else if (process.env.REACT_APP_ENV === "stage") {
    basicExport = staging;
  } else if (process.env.REACT_APP_ENV === "prod") {
    basicExport = production;
  }
  
  export default basicExport;