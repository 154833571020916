import React, { useState, useRef } from 'react';
import './Details.css';
import AppContext from '../../context/AppContext';
import arrow from '../../Assets/Icons/arrowDetails.svg';
import ImgIcon from '../../Assets/Icons/newdoc.svg';
import Infinity from '../../Assets/Icons/infinity.svg';
import EditButton from '../../Assets/Icons/edit1.svg';

const Details = props => {
  const showDetailsBar = () => {
    props.setShowDetails(false);
    props.setMiddleMaxWidth(true);

  };

  return (
    <AppContext.Consumer>
      {context => (
        <div className='details-parent'>
          <div>
            <div className='updiv-details'>
              <img
                className='arrowOverview'
                src={arrow}
                onClick={() => {
                  showDetailsBar();
                }}
              />
              <h1 className='overview'>Overview</h1>
            </div>
          </div>

          <div className='line-details'></div>

          <div className='file-details-div'>
            <div className='img-div-details'>
              <img className='img-details' src={ImgIcon} alt='' />
              <div className='file-tag1'>{props.detailsBarType}</div>
              <div className='file-name-detail'>
                <p className='file-name-details'> {props.detailsBarFileName} </p>
                <p className='last-opened-details'> {props.detailsBarCreatedDate}</p>
              </div>
            </div>
          </div>

          <div className='line2-details'></div>

          <div className='access-details'>
            <div className='access-details-name'>
              <p>Who has access</p>
            </div>

            <div className='pro-pic-div'>
              <div className='img-round'>
                <img className='pro-pic-img' src={localStorage.getItem('profilePicture')}/>
              </div>
              <div className='line-img'></div>
              <div className='img-round2'>
                <img className='infinity-logo' src={Infinity} />
              </div>
            </div>
          </div>

          <div className='line3-details'></div>

          <div className='access-details-name'>
            <p>System propeties</p>
          </div>

          <div className='details-options'>
            <div className='options'>
              <p className='options1'>Type</p>
              <p className='options2'>{props.detailsBarType}</p>
            </div>

            <div className='options'>
              <p className='options1'>Location</p>
              <p className='options2'>{props.detailsBarLocation}</p>
            </div>

            <div className='options'>
              <p className='options1'>Owner</p>
              <p className='options2'>Me  ({localStorage.getItem('full_name')})</p>
              
            </div>

            <div className='options'>
              <p className='options1'>Modified</p>
              <p className='options2'>{props.detailsBarModified}</p>
            </div>

            <div className='options'>
              <p className='options1'>Opened</p>
              <p className='options2'>April 17,2021 With BixCloud web</p>
            </div>

           
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default Details;
