import React from 'react';
import { useEffect } from 'react';
import { contextType } from 'react-modal';
import Propic from '../../Assets/Icons/pro-pic.PNG';
import AppContext from '../../context/AppContext';
import './User.css';





const User = ((props) => {

  
  return (
    <AppContext.Consumer>
       {context =>(
            <div className='Users-list'>


            <div className='User'>
            
            
            <img className='pro-pic-top' src={localStorage.getItem('profilePicture')} alt=""/>
            
            
            
            
                   <div className='Info'>
                   <div className='User-info'>{localStorage.getItem('full_name')} (you)  </div>
                   <div className='User-tag'>{localStorage.getItem('Email')}</div>
            </div>
             
            </div>
                 {/* <div className='user-selector'>
                 <select className='user-select'>
                    <option defaultValue={"Admin"}>Admin</option>
                    <option value="View">View</option>
                 </select>
                 </div> */}
            </div>
       )}
    </AppContext.Consumer>

    
 );
})


export default User
