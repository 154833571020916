import React, { useContext, useState, useRef } from 'react';
import Modal from 'react-modal';
import Folder from '../Folder';
import './PopUp.css';
import Store from '../context';
import AppContext from '../../context/AppContext';
import FolderAdd from '../../Assets/Icons/folder-add.svg';
import close from '../../Assets/Icons/close.svg';

// import {v4 as uuidv4} from "uuid";
const customStyles = {
  content: {
    top: '52%',
    left: '52%',
    right: 'auto',
    height: '28vh',
    width: '30%',
    bottom: 'auto',
    borderRadius: '10px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderTop: '15px solid #3799F4',
    overflowY: 'hidden',
  },
};

const SubFolders = props => {
  let subtitle;
  const [loader, setLoader] = useState(true);
  const { dispatch } = useContext(Store);

  function openModal() {
    setLoader(true);
  }

  function closeModal() {
    setLoader(false);
  }
  function afterOpenModal() {
    // subtitle.style.color = 'rgb(15, 15, 15)';
  }

  return (
    <AppContext.Consumer>
      {context => (
        <div className='new-folder-mod'>
          <Modal
            isOpen={loader}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel='Example Modal'
            ariaHideApp={false}>
            <div className='row'>
              <div className='col-md-12'>
                <br />
                <div className='inputGroupSubFolder'>
                <div className='popup-up'>
                    <img className='icon-add-folder' src={FolderAdd} />
                    <h2
                      className='modal-name-rename'
                      ref={_subtitle => (subtitle = _subtitle)}>
                      {' '}
                      New Folder
                    </h2> 
                    <img className='close-button' src={close}  onClick={() => {
                        props.setCreateSubFolder(false);
                      }} />
                  </div>

                  <input
                    className='rename-box'
                    value={context.todo}
                    autoFocus={true}
                    placeholder='Enter Folder Name'
                    // onKeyUp={handleSubmitForm}
                    onChange={e => {
                      context.setSubFolderName(e.target.value);
                    }}
                  />
                  <div className='input-group-append'>
                  <button
                      className='button-popup1'
                      onClick={() => {
                        props.setCreateSubFolder(false);
                      }}>
                      Cancel
                    </button>
                    <button
                      className='button-popup2'
                      onClick={() => {
                        props.setCreateSubFolder(false);
                        context?.uploadSubFolder();
                      }}>
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default SubFolders;
