import * as React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import './upload_popup.css'
import { MenuList } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import leftFolder from '../../Assets/Icons/foldermiddle.svg';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';



const Popup = ((props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    return (
      <div className='popup'>
        <div class='popup_front'>
          <Button aria-describedby={id}  onClick={handleClick}>
          <div className='button'>Upload</div>
           <div className='icon'><KeyboardArrowDownRoundedIcon fontSize='medium'/></div>
        </Button>
        </div>
        <Popover
        
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Paper
            sx={{
              width: 160,
              maxWidth: '100%',
              backgroundColor: '#f0f0f6',
              borderRadius: '10px',
              boxShadow: 'none',
            }}>
          
            <MenuList>


            <MenuItem
            onClick={() => {
              if (typeof props.actionParam !== "undefined")  props.action(props.actionParam||'');
              else if (typeof props.action !== "undefined")  props.action(); 
              else console.log("Clicked !!!")
            }}>
            <ListItemIcon>
              
              <InsertDriveFileRoundedIcon fontSize='medium' />
            </ListItemIcon>
            <ListItemText>File Upload</ListItemText>
              
          </MenuItem>


          <MenuItem
            onClick={() => {
              
            }}>
            <ListItemIcon>
            <img alt='' src={leftFolder||'' } className='' />
            </ListItemIcon>
            <ListItemText>Folder Upload</ListItemText>
          </MenuItem>

            </MenuList>
          
          </Paper>
        </Popover>
      </div>
    );
})
export default Popup


 
