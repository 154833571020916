import { useState, useEffect } from 'react';
import './UploadModal.css';
import AppContext from '../../context/AppContext';
import ImgIcon from '../../Assets/Icons/newdoc.svg';
import tickProgress from '../../Assets/Icons/tick.png';
import closeProgress from '../../Assets/Icons/close.png';
import close from '../../Assets/Icons/close.svg';

const UploadModal = props => {
  return (
    <AppContext.Consumer>
      {context => (
        <div className='__SendNewMessage'>
          <div>
            <div className='modal-content' style={{ backgroundColor: 'white' }}>
              <div className='send-new-message-other'>
                <div>
                  {props.files.map(file => {
                    return (
                      <div className='img-div-modal'>
                        <div className='progress-modal'>
                          <div className='progress-modal-left'>
                            <img
                              className='img-modal-progress'
                              src={ImgIcon}
                              alt=''
                            />
                            <p className='file-tag-popup-progress'>JPEG</p>
                          </div>
                        </div>

                        <div className='file-name-modal'>
                          {props.folderSelected && props?.uplodingStatus? (
                            <img src={close} className='close-icon-progress' onClick={()=>{
                                context?.setShowUploadPopUp(false);
                                props.setFiles([]);
                                props.setUplodingStatus(false);
                                props.setFolderSelected(false);
                            }} />
                          ) : (
                            ''
                          )}

                          <p className='file-namee-progress'> {file.name}</p>

                          {props.uplodingStatus ? (
                            <div id='hello' className='progress-status-div'>
                              <p className='uploading-status'>
                                uploaded
                                <img
                                  className='close-progress-icon'
                                  src={tickProgress}
                                />
                              </p>
                            </div>
                          ) : (
                            <div id='hello' className='progress-status-div'>
                              <p className='uploading-status'>
                                uploading...{' '}
                                <img
                                  className='close-progress-icon'
                                  src={closeProgress}
                                />
                              </p>
                            </div>
                          )}
                          <div
                            id='myBar'
                            style={{
                              height: '2.5px',
                              width: (context.percentage) + "%",
                              backgroundColor: '#65B4FE',
                              marginLeft: '5%',
                              marginTop: '-2%',
                        
                            }}></div>
                        </div>

                        <div></div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};
export default UploadModal;
