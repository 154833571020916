import React, { useState, useContext } from 'react';
import './Home.css';
import RightSide from '../RightSideComponent/RightSide';
import LeftSideBar from '../LeftSideComponent/LeftSideBar';
import RightSideTopBar from '../RightSideTopComponent/RightSideTopBar';
import AppContext from '../../context/AppContext';
import UploadModal from '../UploadModal/UploadModal';
import TopBar from '../RightSideComponent/topBar'

import DragDropPopUp from '../DragDrop/DragDrop';

const Home = props => {
  const [showImagesFolder, setShowImagesFolder] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [createSubFolder, setCreateSubFolder] = useState(false);
  
  
  const [renameSelectedFile, setRenameSelectedFile] = useState(false);
  const [shareSelectedFile, setShareSelectedFile] = useState(false);
  const [filePreview, setFilePreview] = useState(false);

  const [middleMaxWidth, setMiddleMaxWidth] = useState(true);


  const [showRightSide, setShowRightSide] = useState(false);
  const [root, setRoot] = useState('');
  const [mainRoot, setMainRoot] = useState('All');
  const [showDocumentsFolder, setShowDocumentsFolder] = useState(false);
  const [showSubFolder, setShowSubFolder] = useState(false);
  const [folderSelected, setFolderSelected] = useState(false);


  const [detailsBarType, setDetailsBarType] = useState('');
  const [detailsBarFileName, setDetailsBarFileName] = useState('');
  const [detailsBarModified, SetdetailsBarModified] = useState('');
  const [detailsBarCreatedDate, setDetailsBarCreatedDate] = useState('');
  const [detailsBarLocation, setDetailsBarLocation] = useState('');
  const [uplodingStatus, setUplodingStatus] = useState(false);
  const [files, setFiles] = useState([]);
  

  return (
    <AppContext.Consumer>
      {context => (
        <div className='content'>
          {context.showUploadPopUp ? 
          <UploadModal 
          uplodingStatus={uplodingStatus}
          setUplodingStatus={setUplodingStatus}
          files={files}
          setFiles={setFiles}
          folderSelected={folderSelected}
          setFolderSelected={setFolderSelected}
          /> : ''}
          
          <RightSideTopBar
            root={props.root}
            setShowRightSide={props.setShowRightSide}
            showImagesFolder={showImagesFolder}
            setShowImagesFolder={setShowImagesFolder}
            showRightSide={showRightSide}
            setShowRightSide={setShowRightSide}
            root={root}
            setRoot={setRoot}
            mainRoot={mainRoot}
            setMainRoot={setMainRoot}
            showDocumentsFolder={showDocumentsFolder}
            setShowDocumentsFolder={setShowDocumentsFolder}

          />

           
 
          <DragDropPopUp 
          files={files}
          setFiles={setFiles}
          />

          

          <div className='main-body'>
            <LeftSideBar
              showImagesFolder={showImagesFolder}
              setShowImagesFolder={setShowImagesFolder}
              showRightSide={showRightSide}
              setShowRightSide={setShowRightSide}
              root={root}
              setRoot={setRoot}
              mainRoot={mainRoot}
              setMainRoot={setMainRoot}
              showDocumentsFolder={showDocumentsFolder}
              setShowDocumentsFolder={setShowDocumentsFolder}
              createSubFolder={createSubFolder}
              setCreateSubFolder={setCreateSubFolder}
              showSubFolder={showSubFolder}
              setShowSubFolder={setShowSubFolder}
              folderSelected={folderSelected}
              setFolderSelected={setFolderSelected}
            />
             
            
              
            <RightSide
              showTopBar={props.showTopBar}
              setshowTopBar={props.setShowTopBar}
              showImagesFolder={showImagesFolder}
              setShowImagesFolder={setShowImagesFolder}
              showRightSide={showRightSide}
              setShowRightSide={setShowRightSide}
              root={root}
              setRoot={setRoot}
              mainRoot={mainRoot}
              showDocumentsFolder={showDocumentsFolder}
              setShowDocumentsFolder={setShowDocumentsFolder}
              showDetails={showDetails}
              setShowDetails={setShowDetails}
              createSubFolder={createSubFolder}
              setCreateSubFolder={setCreateSubFolder}
              middleMaxWidth={middleMaxWidth}
              setMiddleMaxWidth={setMiddleMaxWidth}
              showSubFolder={showSubFolder}
              setShowSubFolder={setShowSubFolder}
              renameSelectedFile={renameSelectedFile}
              setRenameSelectedFile={setRenameSelectedFile}

              shareSelectedFile={shareSelectedFile}
              setShareSelectedFile={setShareSelectedFile}

              detailsBarType={detailsBarType}
              setDetailsBarType={setDetailsBarType}
              detailsBarFileName={detailsBarFileName}
              setDetailsBarFileName={setDetailsBarFileName}
              detailsBarModified={detailsBarModified}
              SetdetailsBarModified={SetdetailsBarModified}
              detailsBarCreatedDate={detailsBarCreatedDate}
              setDetailsBarCreatedDate={setDetailsBarCreatedDate}
              detailsBarLocation={detailsBarLocation}
              setDetailsBarLocation={setDetailsBarLocation}
              uplodingStatus={uplodingStatus}
              setUplodingStatus={setUplodingStatus}
              files={files}
              setFiles={setFiles}
              folderSelected={folderSelected}
              setFolderSelected={setFolderSelected}
              filePreview={filePreview}
              setFilePreview={setFilePreview}
            />
          </div>
        </div>
      )}
    </AppContext.Consumer>
  );
};

export default Home;
