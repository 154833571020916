import React from 'react'
import { useEffect } from 'react';
import './ImageButton.css'


const ImageButton = ((props) => {
 return (
  <img className={props.styles||''} 
        src={props.ImgSource||''} 
         onClick={() => {
            if (typeof props.actionParam !== "undefined")  props.action(props.actionParam);
            else props.action();
          }}
  />  
);
})

export default ImageButton
