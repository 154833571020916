import React, { useState, useRef, useEffect, useContext } from 'react';
import AppContext from '../../context/AppContext';
import './PublicRightSide.css';
import viewtypeone from '../../Assets/Icons/row-vertical.svg';
import viewtypetwo from '../../Assets/Icons/element-3.svg';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Popup from '../Upload_popup/upload_popup';
import Share from '../../Assets/Icons/Share.svg';



const  PublicTopBar = props => {

const value = useContext(AppContext);

const uploadImage = useRef(null);


useEffect(() => {
    if (props.folderSelected) {
      value.setShowUploadPopUp(true);
      props.setUplodingStatus(true);
      props.setFolderSelected(true);
      console.log('working fine');
    } else {
      if (value.isUploaded) {
        props.setUplodingStatus(true);
        value.setIsUploaded(false);
        console.log('working fine 2');

        setTimeout(() => {
          value.setShowUploadPopUp(false);
          props.setFiles([]);
          props.setUplodingStatus(false);
          props.setFolderSelected(false);
        }, 3000);
      }
    }
  }, [value.isUploaded]);


  const onImageClick = () => {
    uploadImage.current.click();
    props.setFolderSelected(false);
  };


  const showDetailsBar = () => {
    props.setShowDetails(true);
  };

  const minwidth = () => {
    props.setMiddleMaxWidth(true);
  };

  const maxwidth = () => {
    props.setMiddleMaxWidth(false);
  };

  const handleFile = e => {
    console.log(e.target);
    // for (let file of e.target.files) {
    //   if (!files.includes(file)) {
    //     setFiles([...files, ...e.target.files]);9
    //   }
    // }

    // console.log(e.target.files, 'event');

    return new Promise((resolve, reject) => {
      let filesPromise = [...e?.target?.files].map(file => {
        if (!props.files.includes(file)) {
          props.setFiles([...props.files, ...e.target.files]);
        }
      });

      Promise.all(filesPromise)
        .then(() => {
          console.log('complete');
          resolve('State Updated');
        })
        .catch(() => {
          reject('Stage Failed');
        });
    });

    // for (let file of e.target.files) {
    //   if (!files.includes(file)) {
    //     setFiles([...files, ...e.target.files]);
    //   }
    // }
  };

  return (

    <AppContext.Consumer>
        {context => (
             <div className='topbar-parent'>
             <div className='topbar-parent-search1'>
               <input
                 className='search-bar2'
                 type='text'
                 placeholder='Find Document'
                 name='search'
               />
             </div>

             

             <div className='topbar-parent-type'>
               <img
                 className='view-type1'
                 src={viewtypeone}
                 onClick={() => {
                   props.setShowDetails(false);
                   minwidth();
                 }}
               />
               <img
                 className='view-type1'
                 src={viewtypetwo}
                 onClick={() => {
                   showDetailsBar();
                   maxwidth();
                 }}
               />
             </div>

            <div className='actions'>
              <div className="share">
                <img src={Share}/>
                
              <button className='share-button'>Share</button>
              </div>
              <div className="download">
              <img src={Share}/>
              <button className='download-button'>Download</button>
              </div>
              
            </div>

             
            

             <input
               onChange={async event => {
                 await handleFile(event);
                 console.log('afterComplete');

                 context?.setShowUploadPopUp(true);
                 // context?.setLoader(true);
                 context?.setShowRecentBar(true);
                 context.setUpdate(context.update+1)
               }}
               style={{ display: 'none' }}
               ref={uploadImage}
               type='file'
               className='hidden'
               accept='image/*,video/*,'
               multiple
             />
           </div> 

        )}
    </AppContext.Consumer>
  )

   
};

 export default  PublicTopBar;