import "./DragDrop.css"
import {React, useState} from "react";
import { FileUploader } from "react-drag-drop-files";
import AppContext from "../../context/AppContext";
import Popup from "../UploadModal/UploadModal"





const DragDropPopUp = (props) => {

 
  const [files,setFiles] = useState([]);
 

  return (props.trigger) ? (

    <AppContext.Consumer>
      {context  => (
        <div className="dropzone">
        <FileUploader
          handleChange={async file => {
                        context.dropHandleFiles(file);
                        console.log('afterComplete');
                        context.setShowUploadPopUp(true);
                        context.setShowRecentBar(true);
                        context.setUpdate(context.update+1);
                        setFiles(file);
                      }}
                      
          name='files' 
          multiple = {false}
          disabled = {false}
          children={<span>Drop zone</span>}
          hoverTitle={<span></span>}
          />
         
      </div>
      )}
      
   
        
    
      </AppContext.Consumer>
    
  ) :"";

  
}

export default DragDropPopUp 