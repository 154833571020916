import React, { useState, useEffect } from 'react';
import Home from './Components/HomeComponent/Home.jsx';
import Login from "./Components/Login/Login.jsx";
import Public from "./Components/Public_view/Public.jsx";
import {BrowserRouter as Router, Route, Link, Navlink, Switch,} from 'react-router-dom';
import AppProvider from './context/AppProvider.jsx';




function App() {
    
  return (
    <div className='App'>
      <Router>
        <AppProvider>
          <Route exact path="/public/:id" component={Public}></Route>
          <Route exact path='/' component={Home}></Route>
          <Route exact path="/login" component={Login}/>
          
          {/* <Route exact path='/Upload' component={Upload}></Route> */}
        </AppProvider>
      </Router>
    </div>
  );
}

export default App;
